<template>
  <div class="h-full overflow-y-auto">
    <div class="space-y-4 border-b border-gray-800 px-6 py-4">
      <span class="text-sm font-semibold text-gray-200">Alert Type</span>
      <BaseRadio v-model="alertType" :options="alertTypeOptions.map(e => e.label)" :disabled="alert?.id" />
    </div>
    <AlertsCustomCreateCoin v-if="alertType == 'Coin'" :alert="alert" />
    <AlertsCustomCreatePair v-if="alertType == 'Pairs'" :alert="alert" />
    <AlertsCustomCreateNews v-else-if="alertType == 'News'" :alert="alert" />
    <AlertsCustomCreateSEC v-else-if="alertType == 'SEC Filings'" :alert="alert" />
    <AlertsCustomCreateOnchainTransaction v-else-if="alertType == 'On-Chain Events'" :alert="alert" />
    <AlertsCustomCreateGas v-else-if="alertType == 'ETH Gas'" :alert="alert" />
    <AlertsCustomCreateResearchArticle v-else-if="alertType == 'Research'" :alert="alert" />
    <AlertsCustomCreateFormUnlock v-if="alertType == 'Unlock'" :alert="alert" />

    <AlertsDeliveryOptions :alert="alert" :entity="alertType" :prefill-alert-type="getAlertId()" />
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import useEmitter from '@/composeables/emitter';
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import AlertsCustomCreateCoin from '@/components/alerts/custom/create/AlertsCustomCreateCoin.vue';
import AlertsCustomCreatePair from '@/components/alerts/custom/create/AlertsCustomCreatePair.vue';
import AlertsCustomCreateGas from '@/components/alerts/custom/create/AlertsCustomCreateGas.vue';
import AlertsCustomCreateNews from '@/components/alerts/custom/create/AlertsCustomCreateNews.vue';
import AlertsCustomCreateSEC from '@/components/alerts/custom/create/AlertsCustomCreateSEC.vue';
import AlertsCustomCreateOnchainTransaction from '@/components/alerts/custom/create/AlertsCustomCreateOnchainTransaction.vue';
import AlertsCustomCreateResearchArticle from '@/components/alerts/custom/create/AlertsCustomCreateResearchArticle.vue';
import AlertsCustomCreateFormUnlock from '@/components/alerts/custom/create/AlertsCustomCreateFormUnlock.vue';
import AlertsDeliveryOptions from '@/components/alerts/delivery/AlertsDeliveryOptions.vue';

const emitter = useEmitter();
const store = useStore();

const props = defineProps({
  alert: {
    type: Object,
    default: null
  },
  entity: {
    type: String,
    default: null
  }
});

const alertType = ref('Coin');
const alertTypeOptions = [
  { id: 'Coin', label: 'Coin' },
  { id: 'Pair', label: 'Pairs' },
  { id: 'News', label: 'News' },
  { id: 'OnchainTransaction', label: 'On-Chain Events' },
  { id: 'SEC', label: 'SEC Filings' },
  { id: 'Gas', label: 'ETH Gas' },
  { id: 'ResearchArticle', label: 'Research' },
  { id: 'Unlock', label: 'Unlock' }
];

watch(
  () => props.entity,
  val => {
    if (val) {
      alertType.value = alertTypeOptions.find(e => e.id == props.entity).label;
    }
  }
);

watch(
  () => alertType.value,
  newVal => {
    window.localStorage.setItem(constructLocalStorageName('update:alert-type'), newVal);
    emitter.$emit('update:alert-type', { alertType: alertTypeOptions.find(e => e.label == newVal).id });
  }
);

function constructLocalStorageName(s) {
  let path = store.state.path
    .split('.')
    .map((s, i) => {
      return i == 0 ? s : s.charAt(0).toUpperCase() + s.slice(1);
    })
    .join('');
  s = s.charAt(0).toUpperCase() + s.slice(1);
  return `${path}${s}`;
}

function getAlertId() {
  return alertTypeOptions.find(e => e.label == alertType.value).id;
}

onMounted(() => {
  emitter.$on('clear:alert-type', () => {
    alertType.value = 'Coin';
  });
  if (props.entity) {
    alertType.value = alertTypeOptions.find(e => e.id == props.entity).label;
  } else {
    alertType.value = window.localStorage.getItem(constructLocalStorageName('update:alert-type')) || 'Coin';
  }
});
onBeforeUnmount(() => {
  emitter.$off('clear:alert-type');
});
</script>
